<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 px-0">
          <dx-data-grid
            id="shipmentItemsGrid"
            ref="pendingShipmentItemsGridRef"
            :data-source="dataSource"
            :height="`calc(100vh - 180px)`"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :hover-state-enabled="true"
            :row-alternation-enabled="false"
            :customize-columns="customizeColumns"
            @initialized="onInitialized"
            @cell-dbl-click="onCellDblClick"
            @toolbar-preparing="onToolbarPreparing($event)"
            @exporting="onExporting"
            @option-changed="onOptionChanged"
          >
            <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-scrolling mode="virtual" row-rendering-mode="virtual" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
            <dx-selection show-check-boxes-mode="always" select-all-mode="page" :allow-select-all="true" :width="10" mode="multiple" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <template #buttons="{ data }">
              <div>
                <div v-for="item in data" :key="item.id" class="">
                  <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
                </div>
              </div>
            </template>
            <!--endregion ::DataGird base components -->
            <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
            <template #imageCellTemplate="{ data }">
              <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
            </template>
            <dx-column data-field="batchName" :visible="hideBatchName" :show-in-column-chooser="false" cell-template="batchNameTemplate" />
            <template #batchNameTemplate="{ data }">
              <a href="#" class="font-weight-bold d-block text-nowrap" @click="goToBatch(data)">
                {{ data.value }}
              </a>
            </template>
            <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" />
            <template #titleCellTemplate="{ data }">
              <span role="button" class="dx-product-title text-primary" @click="openProductDetails(data.data)">
                {{ data.value }}
              </span>
            </template>
            <dx-column data-field="amzRank" cell-template="rankingCellTemplate" width="80" caption="Rank" alignment="center" :allow-sorting="false" />
            <template #rankingCellTemplate="{ data }">
              {{ data.value || '-' }}
            </template>
            <dx-column data-field="tracking" caption="Tracking" width="140" cell-template="trackingTemplate" />
            <template #trackingTemplate="{ data }">
              <edit-in-place :row="data" field-name="tracking" @emit-updated="searchByFilter" @emit-search-by-tracking="searchByTracking" />
            </template>
            <dx-column data-field="orderNumber" caption="Order #" cell-template="orderNumberTemplate" :width="140" />
            <template #orderNumberTemplate="{ data }">
              <edit-in-place :row="data" field-name="orderNumber" @emit-updated="searchByFilter" @emit-search-by-orderNumber="searchByOrderNumber" />
            </template>
            <dx-column data-field="purchaseDate" caption="Purchased At" data-type="date" :allow-filtering="false" :allow-sorting="false" />
            <dx-column data-field="shipmentDate" caption="Shipped At" data-type="date" :allow-filtering="false" :allow-sorting="false" />
            <template #header-count-in-bundle="{ data }">
              <div v-b-tooltip.d100.hover.bottom.v-primary title="Total count used in Bundle/MultiPack">
                {{ data.column.caption }}
              </div>
            </template>
            <dx-column data-field="quantity" caption="Expected" :allow-filtering="false" />
            <dx-column data-field="sortedCount" caption="Sorted" :allow-filtering="false" />
            <dx-column data-field="receivedCount" caption="Received" :allow-filtering="false" />
            <dx-column data-field="damagedCount" caption="Problem" :allow-filtering="false" />
            <dx-column data-field="shippedCount" caption="Shipped" :allow-filtering="false" />
            <dx-column data-field="deliveredCount" caption="Delivered" header-cell-template="amazon-fba-delivered" :allow-filtering="false" :allow-sorting="false" />
            <template #amazon-fba-delivered="{ data }">
              <div v-b-tooltip.d100.hover.bottom.v-primary title="Item count delivered to Amazon Fulfillment Centers">
                {{ data.column.caption }}
              </div>
            </template>
            <dx-column caption="Discrepancy" :calculate-cell-value="calculateDiscrepancy" cell-template="discrepeancyTemplate" />
            <template #discrepeancyTemplate="{ data }">
              <span :class="formatDiscrepancyCell(data.value)">
                {{ data.value }}
              </span>
            </template>
            <dx-column data-field="status" cell-template="statusTemplate" :allow-filtering="false" />
            <template #statusTemplate="{ data }">
              <dx-util-button
                :text="formatStatus(data.value)"
                :type="resolveShipDateVariant(data)"
                :element-attr="{ class: 'btn-action-xs' }"
                width="100%"
                hint="Click this button to block/unblock items."
                @click="onBlockUnblockAction(data)"
              />
            </template>
            <dx-column data-field="asin" width="120" caption="ASIN" cell-template="cellASIN" />
            <template #cellASIN="{ data }">
              <a :href="`https://www.amazon.com/gp/product/${data.value}`" target="_blank">
                <span v-b-tooltip.d800.hover.top.v-secondary="'See Item on Amazon'" class="text-capitalize badge badge-pill badge-light-info">
                  {{ data.value }}
                </span>
              </a>
            </template>
            <dx-column data-field="upc" caption="UPC" :width="120" cell-template="upcCellTemplate" />
            <template #upcCellTemplate="{ data }">
              <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
                {{ data.value }}
              </a>
            </template>
            <dx-column data-field="msku" caption="MSKU" width="120" />
            <dx-column data-field="fnsku" caption="FNSKU" width="110" />
            <dx-column data-field="countPerBundle" caption="C/B,MP" cell-template="CountInBundleTemplate" header-cell-template="header-count-per-bundle" :allow-filtering="false" alignment="center" />
            <template #header-count-per-bundle="{ data }">
              <div v-b-tooltip.d100.hover.bottom.v-primary title="Count used per Bundle/MultiPack">
                {{ data.column.caption }}
              </div>
            </template>
            <dx-column data-field="countInBundle" caption="InBundle" cell-template="CountInBundleTemplate" header-cell-template="header-count-in-bundle" :allow-filtering="false" alignment="center" />
            <template #CountInBundleTemplate="{ data }">
              <span v-if="data.value > 0" :class="`${resolveBundleMultiPackVariant(data.value)}`" class="text-capitalize badge badge-pill">
                {{ data.value }}
              </span>
            </template>
            <dx-column data-field="serialNo" width="80" />
            <dx-column data-field="palletId" caption="Pallet Id" cell-template="palletIdTemplate" />
            <template #palletIdTemplate="{ data }">
              <a href="#" class="font-weight-bold d-block text-nowrap" @click="searchByPalletId(data)">
                {{ data.value }}
              </a>
            </template>
            <dx-column data-field="orderNumberSold" caption="Order # Sold" />
            <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" :allow-filtering="false" />
            <dx-column data-field="productType" width="150" caption="Category" alignment="left" :allow-sorting="false" />
            <dx-column data-field="supplier" />
            <dx-column data-field="itemCondition" caption="Cond" :allow-filtering="false" />
            <dx-column data-field="fragile" :allow-filtering="false" />
            <dx-column data-field="packType" caption="Packing" width="70" cell-template="cellPackType" />
            <template #cellPackType="{ data }">
              <span v-if="data.value === 'individual'" v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'" class="d-block badge badge-light-secondary">
                Single
              </span>
              <span v-else-if="data.value === 'bundle'" class="d-block badge badge-light-secondary">
                Bundle
              </span>
              <span v-else-if="data.value === 'multi_pack'" v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'" class="d-block badge badge-light-secondary">
                MP
              </span>
            </template>
            <dx-column data-field="itemCost" caption="Cost" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
            <dx-column data-field="salePrice" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
            <dx-column data-field="action" />
            <dx-column data-field="creationTime" caption="Created At" data-type="date" />
            <dx-column data-field="notes" />
            <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
            <template #itemActions="{ data }">
              <div>
                <dx-util-button type="danger" icon="bi bi-trash" hint="Delete" @click="deleteItem(data)" />
              </div>
            </template>
            <template #filterToolbar>
              <div class="d-flex flex-row align-items-center">
                <div class="mr-half">
                  <dx-util-button icon="back" type="default" class="mx-half" @click="goToShipmentBatches" />
                </div>
                <dx-util-select-box v-model="filters.beforeDate" :data-source="beforeDateOptions"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
                <div class="mr-1">
                  <dx-util-tag-box
                    v-model="filters.statuses"
                    placeholder="Select statuses"
                    style="min-width: 300px;"
                    :data-source="itemStatuses"
                    display-expr="text"
                    value-expr="value"
                    :show-selection-controls="true"
                    @value-changed="searchByFilter"
                  />
                </div>
              </div>
            </template>
          </dx-data-grid>
        </div>
        <div class="col-md-12 px-0">
          <light-box :items="images" :index="index" :effect="'fade'" @close="index = null" />
        </div>
      </div>
      <!--Begin:: Product Details -->
      <product-details v-if="isVisible" :product-id="selectedProductId" :show-product="isVisible" @close=";(isVisible = false), (selectedProductId = null)" />
      <!--End:: Product Details -->
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import InboundShipmentItemsFilter from '@/http/models/search-filters/InboundShipmentItemsFilter'
import shipService from '@/http/requests/ship/shipService'
import CoolLightBox from 'vue-cool-lightbox'
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import Pager from '@core/dev-extreme/utils/pager'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import moment from 'moment'
import { confirm } from 'devextreme/ui/dialog'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import ClientShipmentItemStatusEnum, { getClientShipmentItemStatusEnumList } from '~/enums/clientShipmentItemStatusEnum'
import { pendingItemsSource } from './pendingItemsStore'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import EditInPlace from './EditInPlace'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

const today = new Date()
const fifteenDaysBefore = new Date(today)
fifteenDaysBefore.setDate(today.getDate() - 15)
const thirtyDaysBefore = new Date(today)
thirtyDaysBefore.setDate(today.getDate() - 30)
export default {
  components: {
    'edit-in-place': EditInPlace,
    'light-box': CoolLightBox,
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
  },
  mixins: [GridBase],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    shipBatchId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    const { userId, userCompanyId } = useCurrentUser()
    return {
      userId,
      userCompanyId,
    }
  },
  data: () => ({
    popupVisible: false,
    hideBatchName: false,
    showPageProperties: false,
    dataSource: [],
    filters: {
      query: '',
      statuses: [0, 1],
      beforeDate: today,
    },
    selectedRows: [],
    defaultImg: defaultImageUrl,
    selectedProductId: 0,
    isVisible: false,
    images: [],
    index: null,
  }),
  computed: {
    itemStatuses() {
      return getClientShipmentItemStatusEnumList().filter(el => el.value !== 12)
    },
    dataGrid() {
      const dataGrid = this.$refs.pendingShipmentItemsGridRef.instance
      return dataGrid
    },
    beforeDateOptions() {
      return [
        { text: 'All', value: today },
        { text: '15 days before', value: fifteenDaysBefore },
        { text: '30 days before', value: thirtyDaysBefore },
      ]
    },
  },
  mounted() {
    this.initialLoad()
  },
  created() {},
  methods: {
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    initialLoad() {
      InboundShipmentItemsFilter.setDefaultFilters()
      InboundShipmentItemsFilter.companyId = this.userCompanyId
      InboundShipmentItemsFilter.statusList = this.filters.statuses
      InboundShipmentItemsFilter.endDate = this.filters.beforeDate
      const shipmentItemsFilter = InboundShipmentItemsFilter.getFilters()
      pendingItemsSource.searchValue(shipmentItemsFilter)
      this.dataSource = pendingItemsSource
    },
    reload() {
      const shipmentItemsFilter = InboundShipmentItemsFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(shipmentItemsFilter)
      dataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Shipment Plans',
          type: 'success',
          stylingMode: 'contained',
          onClick: () => {
            this.$router.push({ name: 'uploaded-batches' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipping Plan Items',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadItems()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedItems()
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxTextBox',
        options: {
          placeholder: 'Search...',
          mode: 'search',
          onKeyUp: arg => {
            const pressedKey = arg.event.which
            if (pressedKey === 13) {
              const query = arg.component.option('value')
              this.filters.query = query.trim()
              this.searchByFilter()
            }
            if (pressedKey === 27) {
              arg.component.option('value', '')
              this.filters.query = ''
              this.searchByFilter()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Reload Data',
          onClick: () => {
            this.clearShipmentItemsFilter()
          },
        },
        location: 'after',
      })
    },
    async onDownloadItems(e) {
      const filters = InboundShipmentItemsFilter.getFilters()
      const pager = new Pager({ staticPageSize: 10000 })
      const response = await shipService.queryUncompleted(filters, pager.staticPageable)
      const data = response.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedItems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      worksheet.columns = [
        { header: 'Tracking', key: 'tracking', width: 14 },
        { header: 'Order Number', key: 'orderNumber', width: 16 },
        { header: 'OrderNumberSold', key: 'orderNumberSold', width: 16 },
        { header: 'PalletId', key: 'palletId', width: 16 },
        { header: 'PurchaseDate', key: 'purchaseDate', width: 12 },
        { header: 'ShipmentDate', key: 'shipmentDate', width: 12 },
        { header: 'ExpirationDate', key: 'expirationDate', width: 12 },
        { header: 'ASIN', key: 'asin', width: 13 },
        { header: 'MSKU', key: 'msku', width: 18 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'FNSKU', key: 'fnsku', width: 13 },
        { header: 'SerialNo', key: 'serialNo', width: 12 },
        { header: 'ProductTitle', key: 'title', width: 80 },
        { header: 'Supplier', key: 'supplier', width: 18 },
        { header: 'QTY', key: 'quantity', width: 8 },
        { header: 'Discrepancy', width: 8 },
        { header: 'Condition', key: 'condition', width: 12 },
        { header: 'Cost', key: 'cost', width: 12 },
        { header: 'SalePrice', key: 'salePrice', width: 12 },
        { header: 'Fragile', key: 'fragile', width: 8 },
        { header: 'PackType', key: 'packType', width: 12 },
        { header: 'CountPerBundle', key: 'countPerBundle', width: 8 },
        { header: 'UsedInBundle', key: 'countInBundle', width: 8 },
        { header: 'Received', key: 'receivedCount', width: 8 },
        { header: 'Damaged', key: 'damagedCount', width: 8 },
        { header: 'Shipped', key: 'shippedCount', width: 8 },
        { header: 'Delivered', key: 'deliveredCount', width: 8 },
        { header: 'Status', key: 'status', width: 8 },
        { header: 'Action', key: 'action', width: 12 },
        { header: 'CreatedAt', key: 'creationTime', width: 12 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      const batchName = data.length > 0 ? data[0].batchName : 'Items'
      data.forEach(item => {
        worksheet.addRow([
          item.tracking,
          item.orderNumber,
          item.orderNumberSold,
          item.palletId,
          formatDate(item.purchaseDate),
          formatDate(item.shipmentDate),
          formatDate(item.expirationDate),
          item.asin,
          item.msku,
          item.upc,
          item.fnsku,
          item.serialNo,
          item.title,
          item.supplier,
          item.quantity,
          item.receivedCount - item.quantity,
          item.itemCondition,
          item.itemCost,
          item.salePrice,
          item.fragile,
          item.packType,
          item.countPerBundle,
          item.countInBundle,
          item.receivedCount,
          item.damagedCount,
          item.shippedCount,
          item.deliveredCount,
          item.status,
          item.action,
          formatDate(item.creationTime),
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${batchName}.xlsx`)
      })
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filters.query = ''
        this.filters.query = e.value
      }
    },
    formatStatus(status) {
      return ClientShipmentItemStatusEnum[status].text
    },
    searchByTracking(data) {
      if (data.data.tracking && data.data.tracking !== null && data.data.tracking !== '') {
        this.filters.query = data.data.tracking.trim()
        this.searchByFilter()
      }
    },
    searchByOrderNumber(data) {
      if (data.data.orderNumber && data.data.orderNumber !== null && data.data.orderNumber !== '') {
        this.filters.query = data.data.orderNumber.trim()
        this.searchByFilter()
      }
    },
    searchByPalletId(data) {
      if (data.data.palletId && data.data.palletId !== null && data.data.palletId !== '') {
        this.filters.query = data.data.palletId.trim()
        data.component.searchByText(this.filters.query)
        this.searchByFilter()
      }
    },
    goToShipmentBatches() {
      this.$router.go(-1)
    },
    clearShipmentItemsFilter() {
      this.filters.query = ''
      this.searchByFilter()
    },
    goToBatch(data) {
      this.batchId = data.data.batchId
      this.batchName = data.data.batchName
      this.searchCode = ''
      this.filters.query = ''
      this.includeAllBatches = false
      this.$router.push({
        name: 'route-shipment-items',
        query: {
          batchId: data.data.batchId,
          batchName: data.data.batchName,
        },
      })
      this.initialLoad()
    },
    resolveBundleMultiPackVariant(val) {
      return val > 0 ? 'badge-danger' : ''
    },
    resolveShipDateVariant(data) {
      if (
        data.data.status === ClientShipmentItemStatusEnum.delivered.key
        || data.data.status === ClientShipmentItemStatusEnum.fulfilled.key
        || data.data.status === ClientShipmentItemStatusEnum.shipped.key
      ) return 'success'
      if (data.data.status === ClientShipmentItemStatusEnum.archived.key || data.data.status === ClientShipmentItemStatusEnum.intransit.key) return 'normal'
      if (data.data.status === ClientShipmentItemStatusEnum.blocked.key) return 'danger'
      const days = Math.ceil(moment.duration(moment().diff(moment(data.data.ShipmentDate))).asDays())
      if (days < 14) return 'success'
      return 'default'
    },
    searchByFilter() {
      InboundShipmentItemsFilter.endDate = this.filters.beforeDate
      InboundShipmentItemsFilter.statusList = this.filters.statuses
      InboundShipmentItemsFilter.q = this.filters.query
      const filters = InboundShipmentItemsFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    deleteItem(e) {
      const confirmResult = confirm("Are you sure you want to delete this Item record? You won't be able to revert this!", 'Confirmation')
      confirmResult.then(dialogResult => {
        if (dialogResult) {
          shipService.deleteItemById(e.row.key).then(() => {
            const dataSource = this.dataGrid.getDataSource()
            dataSource.reload()
          })
        }
      })
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Items.xlsx')
        })
      })
      e.cancel = true
    },
    calculateDiscrepancy(row) {
      return row.receivedCount - row.quantity
    },
    onBlockUnblockAction(row) {
      const data = { ...row.data }
      const formData = {
        productId: 0,
        status: 0,
        notes: '',
      }
      this.itemStatusUpdatePopupForm = { ...formData }
      const popup = this.$refs.itemStatusUpdatePopupRef.instance
      this.itemStatusUpdatePopupForm.productId = data.productId
      if (data.status !== ClientShipmentItemStatusEnum.blocked.key) {
        this.itemStatusUpdateTitle = 'Block'
        this.itemStatusUpdatePopupForm.notes = 'This item can not be replenished to Amazon due to the Storage Limits.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.blocked.value
      } else {
        this.itemStatusUpdateTitle = 'Unblock'
        this.itemStatusUpdatePopupForm.notes = 'This item is now eligible to replenish to Amazon due to available storage capacity.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.intransit.value
      }
      popup.show()
    },
    openProductDetails(e) {
      this.selectedProductId = e.productId
      this.isVisible = true
    },
    formatDiscrepancyCell(v) {
      if (v < 0) return 'badge badge-danger d-block'
      if (v > 0) return 'badge badge-warning d-block'
      return 'badge d-block'
    },
  },
}
</script>

<style lang="scss">
#shipmentItemsGrid {
  .dx-toolbar-before {
    align-items: center;
  }
}

#infoToolbar {
  .media-heading {
    color: white !important;
  }

  .b-icon.bi {
    font-size: 2rem;
  }
}
</style>
