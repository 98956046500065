/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class InboundShipmentItemsFilter extends BaseSearchFilter {
  #statusList = []

  constructor() {
    super()
    this.#statusList = this.statusList
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.statusList = []
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      statusList: this.statusList,
    }
    return filter
  }

  /** @returns {Array} */
  get statusList() {
    return this.#statusList
  }
  /** @param {Array} value */
  set statusList(value) {
    this.#statusList = value
  }
}

export default new InboundShipmentItemsFilter()
